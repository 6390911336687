.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.fifth-width {
  max-width: min(30vw, 30vh, 200px);
}

.centered {
  margin: auto;
}

.video-banner {
  max-height: 500px;
}

.video-wrapper {
  position: relative;
  max-height: inherit;
  overflow: hidden;
}

.video-wrapper > video {
  width: 100%;
  display: block;
  object-fit: cover;
}

.video-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);

  color: #ffffff;
}

.transparent-background {
  background-color: transparent !important;
}

.card-overlay {
  background: rgba(265, 265, 265, 0.6);
  height: 70px;
}

.card-button {
  width: 100%;
}

.content-wrapper {
  flex: 1
}

.has-border {
  border-style: solid;
  border-color: black;
  border-width: 1px;
}

.notification-padding {
  padding: 1.25rem 1.25rem 1.25rem 1.25rem !important;
}

.specification-list {
  list-style: square;
}

.home-gallery > .column {
  max-width: 300px;
}